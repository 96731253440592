import React from 'react';

export default function privacy() {
  return (
    <>
      <h1 style={{ paddingTop: '80px', textAlign: 'center' }}>
        PRIVACY AND POLICIES
      </h1>

      <div style={{ margin: '40px' }}>
        <p>
          <strong>Triple Aim Care Privacy Policy</strong>
        </p>
        <p>
        At Triple Aim Care Limited, accessible through{' '}
          <a href="http://www.afyabook.com">www.afyabook.com</a>
          {' '}
          (the “Site”), we are committed to protecting and respecting your privacy. 
          This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our services, including medications, online doctor consultations, and laboratory services, either directly or through third parties.
        </p>

        <ol start="1">
          <li>
            <strong>PERSONAL INFORMATION WE COLLECT</strong>
          </li>
        </ol>
        <p>
        We collect different types of personal information depending on how you interact with our services:
        </p>

        <ol type="a">
          <li>
          <strong>Device Information:</strong> Automatically collected when you browse our Site, including your web browser, IP address, time zone, and installed cookies. Technologies like “cookies,” “log files,” “web beacons,” and “pixels” help us collect this information.
          </li>
          <li>
          <strong>Order Information:</strong> Collected when you make purchases, including name, billing address, shipping address, payment details, email address, and phone number.
          </li>
          <li>
          <strong>Medical Information:</strong> If you access our healthcare services, we may collect sensitive health-related information, including medical history, test results, prescriptions, and consultations with healthcare providers.
          </li>
          <li>
          <strong>Professional Information:</strong> If you are a healthcare professional using our services, we collect professional registration information, medical license numbers, and areas of expertise.
          </li>
          <li>
          <strong>Financial Information:</strong> Bank account numbers, credit card details, and other relevant payment information are collected for processing orders.
          </li>
        </ol>

        <p>
        We also collect and process health data in accordance with the Kenyan Data Protection Act, 2019 to ensure compliance with local laws.
        </p>

        <ol start="2">
          <li>
            <strong>
            HOW WE USE YOUR PERSONAL INFORMATION
            </strong>
          </li>
        </ol>

        <p>
        We use the personal information collected to:
        </p>

        <ol type="a">
          <li>
          Provide and improve our products and services.
          </li>
          <li>
          Process your orders, including payments, shipping, and issuing order confirmations.
          </li>
          <li>
          Facilitate consultations with healthcare professionals.
          </li>
          <li>
          Provide laboratory results.
          </li>
          <li>
          Ensure compliance with legal and regulatory obligations.
          </li>
          <li>
          Conduct data analytics to improve user experience on our Site.
          </li>
        </ol>
        <p>
        We process your health-related data under the supervision of healthcare providers and professionals, ensuring all data is handled with the utmost confidentiality in compliance with local regulations. Any sensitive health data collected is protected under the rules governing medical confidentiality.
        </p>

        <ol start="3">
          <li>
            <strong>Sharing Your Personal Information</strong>
          </li>
        </ol>
        <p>
        We may share your personal information with third parties under the following circumstances:
        </p>
        <ol type="a">
          <li>
          Service Providers: Trusted third-party companies that provide services on our behalf (e.g., shipping, payment processing, and laboratories). We ensure that these third parties comply with robust data protection standards.
          </li>
          <li>
          Healthcare Providers: Third-party doctors and laboratories who may access your medical information to provide healthcare services.
          </li>
          <li>
          Legal Obligations: In accordance with applicable laws, we may disclose personal information if required by law enforcement, government bodies, or legal processes.
          </li>
        </ol>
        <p>
        We comply with the Kenyan Data Protection Act, which mandates that any transfer of personal data outside Kenya is safeguarded with appropriate protections.
        </p>

        <ol start="4">
          <li>
            <strong>HOW WE PROTECT YOUR DATA</strong>
          </li>
          <p>
          We implement appropriate technical and organizational measures to secure your personal data from unauthorized access, disclosure, alteration, and destruction. This includes encryption and pseudonymization where applicable. However, despite our efforts, no transmission over the internet or method of storage is 100% secure. If you suspect any data breach, please contact us immediately.
          </p>          
        </ol>
        
        <ol start="5">
          <li>
            <strong>YOUR RIGHTS</strong>
          </li>
        </ol>
        <p>
        You have the right to:
        </p>
        <ol type="a">
          <li>
          Access your personal information and request corrections or updates.
          </li>
          <li>
          Withdraw your consent to the processing of your data at any time, especially for marketing purposes.
          </li>
          <li>
          Request deletion of your data unless we are legally required to retain it.
          </li>
        </ol>
        <p>
        For more information about your rights under the Data Protection Act of 2019, please refer to the Act or contact us.
        </p>

        <ol start="6">
          <li>
            <strong>DATA RETENTION</strong>
          </li>
        </ol>
        <p>
        We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. If you wish to request the deletion of your personal data, please contact us at the details provided below.
        </p>

        <ol start="7">
          <li>
            <strong>Third-Party Links</strong>
          </li>
        </ol>
        <p>
        Our Site may contain links to third-party websites or applications that are not operated by us. Please be aware that we are not responsible for the privacy practices of those third-party sites. We recommend reading their privacy policies before submitting any personal information.
        </p>

        <ol start="8">
          <li>
            <strong>MINORS</strong>
          </li>
        </ol>
        <p>
        Our services are not intended for individuals under the age of 18 without a guardian support.
        </p>

       <ol start="9">
          <li>
            <strong>CHANGES</strong>
          </li>
        </ol>
        <p>
          We may update this privacy policy from time to time to reflect, for
          example, changes to our practices or for other operational, legal, or
          regulatory reasons.
        </p>

        <ol start="10">
          <li>
            <strong>CONTACT US</strong>
          </li>
        </ol>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at &nbsp;
          <span style={{ color: 'blue' }}>support@patameds.com</span>
          .
        </p>
      </div>
    </>
  );
}
